<template>
  <div class="body-bg">
    <van-nav-bar
      title="个人中心"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <div style="height: 10px"></div>
    <div class="steps-box">
      <van-steps active-color="#F3C301" direction="vertical" active="1">
        <van-step class="step-item">
          <p class="title">发起提现申请</p>
        </van-step>
        <van-step class="step-item">
          <p class="title">处理中</p>
          <p class="des">预计一天内到账</p>
        </van-step>
        <van-step class="step-item">
          <p class="title active">到账成功</p>
        </van-step>
      </van-steps>
    </div>
    <div class="kbox-gray5"></div>
    <div class="content">
      <van-cell-group :border="false">
        <!--
        <van-cell>
          <template #title>
            <div class="p-title">提现金额</div>
          </template>
          <template #default>
            <div class="p-value">￥230.0</div>
          </template>
        </van-cell>
        -->
        <van-cell>
          <template #title>
            <div class="p-title">提现方式</div>
          </template>
          <template #default>
            <div class="p-value-p">微信</div>
          </template>
        </van-cell>
        <!--
        <van-cell>
          <template #title>
            <div class="p-title">提现账号</div>
          </template>
          <template #default>
            <div class="p-value-p">子梁</div>
          </template>
        </van-cell>
        -->
      </van-cell-group>
    </div>
    <div class="kbox-gray5"></div>
    <div class="footer">
      <p class="title">到账查询</p>
      <p class="text">1、微信：我的-钱包-零钱-零钱明细</p>
      <div class="button">
        <van-button round block type="info" color="#F3C301" @click="ensure"
          ><p class="color333">我知道了</p></van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    ensure: function() {
      this.$router.push("/person");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.body-bg {
  background-color: #ffffff;
}
.steps-box .step-item {
  padding-left: 20px;
  margin: 20px 0;
}
.steps-box .step-item .title {
  font-size: 13px;
  color: #333;
}
.steps-box .step-item .title.active {
  font-size: 13px;
  font-weight: 600;
  color: #333;
}
.steps-box .step-item .des {
  font-size: 13px;
  color: #b3b3b3;
}
.p-title {
  font-size: 13px;
  color: #333;
}
.p-value {
  font-size: 13px;
  color: #f5c204;
}
.p-value-p {
  font-size: 13px;
  color: #333;
  font-weight: 600;
}
.footer {
  padding: 10px 16px;
  background-color: #fff;
}
.footer .title {
  font-size: 13px;
  color: #b3b3b3;
}
.footer .text {
  font-size: 13px;
  color: #b3b3b3;
  margin-top: 16px;
}
.footer .button {
  margin: 20px 20px;
}
</style>
